<template>
    <v-list
        dense
        id="history-list"
        height="400px"
        style="overflow-y: scroll"
        v-if="Object.keys(history).length"
    >
        <template v-for="(call, index) in history.records">
            <v-list-item :key="index" class="list-item">
                <v-hover v-slot="{ hover }">
                    <v-list-item-content class="my-2 py-0">
                        <div class="d-flex align-center jusify-space-between">
                            <v-avatar
                                :color="itemColor(call)"
                                size="40"
                                class="mr-6"
                            >
                                <v-icon height="30" class="white--text"
                                    >mdi-account</v-icon
                                >
                            </v-avatar>

                            <div style="width: 230px" class="d-flex-column">
                                <span
                                    :class="`text-subtitle-1 font-weight-bold ${itemTextColor(
                                        call
                                    )}`"
                                    >{{
                                        $phoneFormat(call.to.phoneNumber)
                                    }}</span
                                >
                                <div class="d-flex align-center">
                                    <v-icon small :color="itemColor(call)">{{
                                        itemIcon(call)
                                    }}</v-icon>
                                    <span
                                        :class="`ml-2 text-caption ${itemTextColor(
                                            call
                                        )}`"
                                        >{{ call.result }}</span
                                    >
                                    <v-icon
                                        small
                                        class="ml-1"
                                        :color="itemColor(call)"
                                        >{{ directionIcon(call) }}</v-icon
                                    >

                                    <span
                                        :class="`ml-2 text-caption ${itemTextColor(
                                            call
                                        )}`"
                                        >{{ call.direction }}</span
                                    >
                                </div>
                            </div>
                            <div
                                v-if="!hover"
                                class="d-flex-column align-center mt-1"
                                style="width: 150px"
                            >
                                <span class="grey--text text--darken-1">{{
                                    new Date(call.startTime).toLocaleString()
                                }}</span>
                                <div class="d-flex align-center mt-1">
                                    <v-icon small class="mr-1 grey--text"
                                        >mdi-timer-sand-full</v-icon
                                    >
                                    <span
                                        class="text-caption text-lighten grey--text text--darken-1"
                                        >{{ call.duration }} seconds</span
                                    >
                                </div>
                            </div>
                            <div
                                v-else
                                class="d-flex align-center mt-1"
                                style="width: 150px"
                            >
                                <v-btn
                                    class="mx-auto"
                                    color="secondary darken-2"
                                    small
                                    @click.stop="
                                        selectPhone(
                                            $phoneFormat(call.to.phoneNumber)
                                        )
                                    "
                                >
                                    <v-icon small>mdi-phone</v-icon>
                                </v-btn>
                            </div>
                        </div>
                    </v-list-item-content>
                </v-hover>
            </v-list-item>
            <v-divider
                :key="`divider-${index}`"
                v-if="index < history.records.length - 1"
            ></v-divider>
        </template>
    </v-list>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
    name: "call-history",
    components: {},
    props: {},
    data() {
        return {};
    },
    computed: {
        ...mapGetters("Calls", ["history", "initialized"]),
    },
    mounted() {},
    watch: {
        initialized: {
            immediate: true,
            handler() {
                this.fetchCallHistory();
            },
        },
    },
    methods: {
        ...mapActions("Calls", ["fetchCallHistory"]),
        selectPhone(phoneNumber) {
            this.$emit("update:phone", phoneNumber);
        },
        itemTextColor({ result }) {
            switch (result) {
                case "Call connected":
                    return "primary--text text--darken-2";
                case "Hang Up":
                    return "error--text text--darken-2";
            }
        },
        itemColor({ result }) {
            switch (result) {
                case "Call connected":
                    return "primary darken-2";
                case "Hang Up":
                    return "error darken-2";
            }
        },
        itemIcon({ result }) {
            switch (result) {
                case "Call connected":
                    return "mdi-phone-check";
                case "Hang Up":
                    return "mdi-phone-remove";
            }
        },
        directionIcon({ direction }) {
            switch (direction) {
                case "Outbound":
                    return "mdi-arrow-right-circle";
                case "Inbound":
                    return "mdi-arrow-left-circle";
            }
        },
    },
};
</script>

<style scoped>
.list-item:hover {
    background: var(--v-secondary-lighten3) !important;
}
.list-item:hover .list-item-text {
    color: white;
}
.v-list--dense .v-list-item {
    min-height: 20px !important;
}
</style>