var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return Object.keys(_vm.history).length
    ? _c(
        "v-list",
        {
          staticStyle: { "overflow-y": "scroll" },
          attrs: { dense: "", id: "history-list", height: "400px" }
        },
        [
          _vm._l(_vm.history.records, function(call, index) {
            return [
              _c(
                "v-list-item",
                { key: index, staticClass: "list-item" },
                [
                  _c("v-hover", {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var hover = ref.hover
                            return [
                              _c(
                                "v-list-item-content",
                                { staticClass: "my-2 py-0" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex align-center jusify-space-between"
                                    },
                                    [
                                      _c(
                                        "v-avatar",
                                        {
                                          staticClass: "mr-6",
                                          attrs: {
                                            color: _vm.itemColor(call),
                                            size: "40"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "white--text",
                                              attrs: { height: "30" }
                                            },
                                            [_vm._v("mdi-account")]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "d-flex-column",
                                          staticStyle: { width: "230px" }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              class:
                                                "text-subtitle-1 font-weight-bold " +
                                                _vm.itemTextColor(call)
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$phoneFormat(
                                                    call.to.phoneNumber
                                                  )
                                                )
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "d-flex align-center"
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    small: "",
                                                    color: _vm.itemColor(call)
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.itemIcon(call))
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  class:
                                                    "ml-2 text-caption " +
                                                    _vm.itemTextColor(call)
                                                },
                                                [_vm._v(_vm._s(call.result))]
                                              ),
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "ml-1",
                                                  attrs: {
                                                    small: "",
                                                    color: _vm.itemColor(call)
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.directionIcon(call)
                                                    )
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  class:
                                                    "ml-2 text-caption " +
                                                    _vm.itemTextColor(call)
                                                },
                                                [_vm._v(_vm._s(call.direction))]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      ),
                                      !hover
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex-column align-center mt-1",
                                              staticStyle: { width: "150px" }
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "grey--text text--darken-1"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      new Date(
                                                        call.startTime
                                                      ).toLocaleString()
                                                    )
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex align-center mt-1"
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass:
                                                        "mr-1 grey--text",
                                                      attrs: { small: "" }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "mdi-timer-sand-full"
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "text-caption text-lighten grey--text text--darken-1"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(call.duration) +
                                                          " seconds"
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        : _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex align-center mt-1",
                                              staticStyle: { width: "150px" }
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "mx-auto",
                                                  attrs: {
                                                    color: "secondary darken-2",
                                                    small: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      _vm.selectPhone(
                                                        _vm.$phoneFormat(
                                                          call.to.phoneNumber
                                                        )
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { small: "" } },
                                                    [_vm._v("mdi-phone")]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                    ],
                                    1
                                  )
                                ]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  })
                ],
                1
              ),
              index < _vm.history.records.length - 1
                ? _c("v-divider", { key: "divider-" + index })
                : _vm._e()
            ]
          })
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }